.victoryFrame {
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  background: #ffffff1a;
  border-radius: 40px;
  backdrop-filter: blur(25px);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 20px;
}
.alarmClock {
  margin-top: 10px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.alarmClockImg {
  position: absolute;
  left: 39%;
}
.spentTime {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Primary, #004a8e);
  width: 175px;
  height: 58px;
  border-radius: 24px;
}
.spentTime span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #004a8e;
}
.CorrectAndWrongPoint {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: 150px;
  margin-right: 150px;
}
.correct {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.plusPoint {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.wrong {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.NoOfCorrectAnswer {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 10px;
}
.correctText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #1c3055;
}
.NoOfPoint {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 20px;
  line-height: 22.4px;
  color: #1c3055;
}
.NoOfWrongAnswer {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 10px;
}
.NoOfWrongPoint {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 20px;
  line-height: 22.4px;
  color: #f74354;
}
.wrongText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #f74354;
}
.NoOfPlusPoint {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 25px;
  line-height: 22.4px;
  color: #b35b3d;
  margin-right: 20px;
  border: 1px solid #fff;
  border: none;
  background: linear-gradient(0deg, #edd6b9 0%, #d4b492 100%);
  width: 140px;
  height: 48.98px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding-top: 15px;
}
.PlusPintIcon {
  position: absolute;
  left: 52%;
}
.victoryBtnSection {
  margin-top: 30px;
}
.nextBtnVictory {
  margin-bottom: 20px;
}
.nextBtnVictory button {
  width: 25%;
  height: 48px;
  box-shadow: 0px 3px 1px 0px #00000040;
  border-radius: 10px;
  border-color: #820d344d;
  background: #bd0c47;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #ffffff;
}
.checkAnswerBtn button {
  width: 25%;
  height: 48px;
  box-shadow: 0px 3px 1px 0px #00000040;
  border-radius: 10px;
  border-color: #bd0c47;
  background: transparent;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #bd0c47;
}
.playerAndHostSection {
  display: flex;
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
  gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hostDeatils {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.playerDeatils {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.profileOnevsOneLeaderBoard {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hostName span {
  font-family: "Hallowest";
  font-weight: 400;
  size: 22px;
  line-height: 35.2px;
  color: #3a3a3a;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.OneVsOnePoint {
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #fff;
  border: none;
  background: linear-gradient(0deg, #edd6b9 0%, #d4b492 100%);
  border-radius: 20px;
  height: 49.55px;
  display: flex;
}
.OneVsOnePoint span {
  color: #b35b3d;
  font-size: 20px;
  font-weight: 600;
  font-family: "Mitr";
}
