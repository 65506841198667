@font-face {
  font-family: "Hallowest";
  src: url("../src/component/assests/fonts/hallowest.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../src/component/assests/fonts/Poppins.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Joti One";
  src: url("../src/component/assests/fonts/JotiOne.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url("../src/component/assests/fonts/PlusJakartaSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mitr";
  src: url("../src/component/assests/fonts/Mitr.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

