.HomePage {
  padding-left: 50px;
  padding-right: 50px;
  background: linear-gradient(
    108.13deg,
    #d4deee 2.31%,
    #6b91cc 30.89%,
    #80a9e7 67.08%,
    #d2daeb 97.56%
  );
  min-height: 100vh;
  padding-top: 40px;
}

.OtherOptions span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.81px;
  color: #000000;
}
.cardSection {
  display: flex;
  gap: 50px;
}
.card1 {
  flex: 1;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px #00000040;
  width: 320px;
  height: auto;
  border-radius: 12px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.card2 {
  flex: 1;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px #00000040;
  width: 320px;
  height: auto;
  border-radius: 12px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.card3 {
  flex: 1;
  border: 1px solid #fff;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px #00000040;
  width: 320px;
  height: auto;
  border-radius: 12px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.cardPane {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
.leftPane {
  flex: 2;
}
.rightPane {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.InviteBtnCard1 {
  justify-content: center;
  text-align: center;
  margin-top: -10px;
}
.InviteBtnCard2 {
  justify-content: center;
  text-align: center;
  margin-top: -10px;
}
.InviteBtnCard3 {
  justify-content: center;
  text-align: center;
  margin-top: 19%;
}
.playwithText span {
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  line-height: 21.78px;
  color: #004a8e;
}
.leftPane span {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #004a8e;
}
.rightPane span {
  font-family: "Joti One";
  font-weight: 400;
  font-size: 34px;
  line-height: 45.36px;
  background: linear-gradient(180deg, #ff0303 0%, #ff5e03 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.vs {
  width: 27.97px;
  height: 39.03px;
}
.team {
  width: 26.68px;
  height: 26.68px;
}
.InviteBtnCard1 button {
  width: 85%;
  height: 38.5px;
  border-radius: 10px;
  background-color: #bd0c47;
  border-color: #820d344d;
  box-shadow: 0px 3px 1px 0px #00000040;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  margin-bottom: 20px;
}
.InviteBtnCard2 button {
  width: 85%;
  height: 38.5px;
  border-radius: 10px;
  background-color: #0cbdbd;
  border-color: #0d58824d;
  box-shadow: 0px 3px 1px 0px #00000040;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  margin-bottom: 20px;
}
.InviteBtnCard3 button {
  width: 85%;
  height: 38.5px;
  border-radius: 10px;
  background-color: #4fbd0c;
  border-color: #077d364d;
  box-shadow: 0px 3px 1px 0px #00000040;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  margin-bottom: 20px;
}
.bottomSection {
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
}
.card1_bottom {
  flex: 2;
  border: 3px solid #fff;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.card2_bottom {
  flex: 1;
  border: 3px solid #fff;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.card3_bottom {
  flex: 3;
}
.playerSection {
  display: flex;
  gap: 70px;
}
.player1 {
  flex: 1;
}
.player2 {
  flex: 1;
}
.player3 {
  flex: 1;
}
.TopPlayers span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.81px;
  color: #000000;
}
.playerBackGround {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.medal {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -20px;
}
.PlayerName {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.PlayerName span {
  font-weight: 700;
  font-size: 14px;
  line-height: 17.64px;
  color: #0c4a5a;
}
.howtoPlay {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.howtoPlay span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.81px;
  color: #000000;
}
.topImage {
  align-items: center;
  justify-content: center;
  text-align: center;
  object-fit: cover;
}
.PlayNow {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -50px;
}
.PlayNow button {
  text-align: center;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 22px;
  line-height: 27.72px;
  background-color: #bd0c47;
  color: #ffffff;
  border-radius: 14px;
  height: 64px;
  border: none;
  width: 15%;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.AppName {
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  left: 46%;
  top: 15%;
}
.ExamHubName span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 50px;
  line-height: 80.02px;
  color: #ffff;
}
.PlayName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffff;
}

.modalPlay .modal-dialog {
  border-radius: 44px;
}

.modalPlay .modal-content {
  border: 3px solid;
  border-image-source: linear-gradient(
    135.59deg,
    rgba(199, 199, 199, 0.79) 1.28%,
    rgba(134, 134, 134, 0.24) 96.26%
  );

  border-radius: 44px;
}

.ModelIcon {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.oneMoreText {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.oneMoreText span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 28px;
  line-height: 35.28px;
  color: #4e4e4e;
}
.pleseSelectText {
  margin-bottom: 20px;
  margin-top: 20px;
}
.pleseSelectText span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #4e4e4e;
}

.InputText-PlayModal {
  margin-bottom: 5px;
  margin-top: 5px;
}
.InputText-PlayModal span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.64px;
  color: #484848;
}
.modalStartBtn {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.modalStartBtn button {
  height: 48px;
  width: 60%;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #ffffff;
  box-shadow: 0px 3px 1px 0px #00000040;
  border-radius: 10px;
  background: #bd0c47;
  border: #820d344d;
}
.btnAnimal img {
  width: 25%;
  height: 20%;
  margin-left: 10%;
}
.playButtonSection {
  margin-top: 8%;
}
.modalPlay .btn-close {
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 5px;
  background-color: #b0abab29;
  width: 40px;
  height: 40px;
}
.selectGameModeText {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 15px;
}
.selectGameModeText span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 24px;
  line-height: 30.24px;
  color: #484848;
}
.oneVsOneText {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 18px;
  background: #d9d9d980;
  box-shadow: 0px 4px 4px 3px #00000040;
  margin-left: 10%;
  margin-right: 10%;
  height: 80.33px;
  margin-bottom: 10px;
}
.oneVsOneText span {
  font-family: "Joti One";
  font-weight: 400;
  font-size: 34px;
  line-height: 45.36px;
  background: linear-gradient(180deg, #ff0303 0%, #ff5e03 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.teamVSteam {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  border: 1px solid #d2d2d2;
  border-radius: 18px;
  background: #d9d9d980;
  box-shadow: 0px 4px 4px 3px #00000040;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 80.33px;
}
.buttonText {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.buttonText span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 16px;
  line-height: 30.24px;
  color: #484848;
}

.modalHeader {
  border-bottom: none;
}
