.leaderBoardContainer {
  min-height: 77.5vh;
  overflow: hidden;
}
.BottomSection {
  margin-top: auto;
  width: 100%;
}

.LeftGrasses {
  position: absolute;
  left: 13%;
  top: 80%;
}
.RightGrasses {
  position: absolute;
  left: 83%;
  top: 80%;
}
.RightAnimal-LeaderBoard {
  margin-right: 180px;
}
.LeaderBoardContent {
  position: absolute;
  z-index: 1;
  margin-top: 25px;
}
.leaderBoardFrame {
  border: 1px solid #a9a9a9;
  background: #11637833;
  border-radius: 12px;
}
.leaderBoardImg {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.medalSection {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 80px;
}
.sideTwoImg {
  margin-top: 30px;
}
.NameInWinners {
  border: 1px solid #fff;
  box-shadow: 0px 4px 1px 0px #00000040;
  border-radius: 5px;
  background: #f5f5f5;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  margin-top: 5px;
}
.NameInWinners span {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  color: #940006;
}
.scoreSection {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 2px solid #c69c6d;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: #ffd850;
}
.scoreSection span {
  font-family: "Mitr";
  font-weight: 500;
  font-size: 12px;
  line-height: 18.84px;
  color: #000000;
}

.leaderBoard {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 300px;
  margin-right: 300px;
  border: 3px solid #ffffffc9;
  border-radius: 40px;
  backdrop-filter: blur(25px);
  background: #ffffff1a;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.nameSection-leaderBoard {
  overflow-y: auto;
  max-height: 150px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
}

.nameSection-leaderBoard::-webkit-scrollbar {
  width: 9px;
}

.nameSection-leaderBoard::-webkit-scrollbar-track {
  width: 10px;
  background-color: #ccc;
  border-radius: 10px;
}

.nameSection-leaderBoard::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.nameSection-leaderBoard::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.winnerScoreName {
  display: flex;
  gap: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid
    linear-gradient(
      135.59deg,
      rgba(255, 255, 255, 0.79) 1.28%,
      rgba(255, 255, 255, 0.24) 96.26%
    );
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #ffffff80;
  margin-top: 5px;
  margin-bottom: 14px;
  margin-left: 20px;
  margin-right: 20px;
}
.Place {
  flex: 1;
}
.ProfilePicture-leaderBoard {
  flex: 1;
}
.PlayerName-leaderBoard {
  flex: 8;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.NoOfpoint {
  flex: 1;
  display: flex;
}
.Place span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #000000;
}
.PlayerName-leaderBoard span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 14px;
  line-height: 17.64px;
  color: #282828;
}
.NoOfpoint span {
  font-family: "Mitr";
  font-weight: 400;
  font-size: 14px;
  line-height: 21.98px;
  color: #000000;
}
.timeSection {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 35%;
  gap: 20px;
}
.WeeklyandYearly span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #00294e;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Monthly span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #00294e;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.WeeklyandYearly {
  border: 2px solid #c69c6d;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: linear-gradient(180deg, #ffe871 -16.78%, #ffd74e 80.7%);
  cursor: pointer;
}
.Monthly {
  border: 2px solid #c69c6d;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: linear-gradient(180deg, #ffe871 -16.78%, #ffd74e 80.7%);
  cursor: pointer;
}
.spinner {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: auto;
}

.WeeklyandYearly.active,
.Monthly.active {
  background: #c90741;
  border: 2px solid #de5c25;
}

.WeeklyandYearly.active span,
.Monthly.active span {
  color: #fff;
}
.ProfilePictureLeaderBoard {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
