.setttingContainer {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.topcardSection {
  display: flex;
  gap: 40px;
}
.settingLeftsideCard {
  flex: 1;
}
.settingRightsideCard {
  flex: 1;
}
.yourProfile {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.2px;
  color: #2a3547;
}
.changeYourProfile {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: #5b5b5b;
}
.ProfilePicture {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}
.ProfilePictureImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.resetBtn {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}
.resetBtn button {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #fa896b;
  height: 36px;
  width: 60px;
  border-radius: 4px;
  border: 1px solid #fa896b;
}
.allowText {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}
.allowText span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: #5b5b5b;
}
.containerFrame {
  border: 1px solid #eaeff4;
  background: #ffffff;
  border-radius: 10px;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
}

.settingLeftsideCard {
  border: 1px solid #ececec;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 0px #919eab33;
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  height: 350px;
}
.changeYourPassword {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: #7c8fac;
}
.formSettings {
  margin-top: 8px;
}
.InputText-Setting {
  margin-bottom: 5px;
}
.InputText-Setting span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
  color: #2a3547;
  margin-bottom: 20px;
}
:optional {
  font-size: 13px;
}
.saveBtn {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.saveBtn button {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
  height: 38.5px;
  border-radius: 10px;
  border: none;
  background: #bd0c47;
  border-bottom: 3px solid #820d34;
  box-shadow: 0px 3px 1px 0px #00000040;
  width: 30%;
}
.settingRightsideCard {
  border: 1px solid #ececec;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 0px #919eab33;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  height: 350px;
}
.changePersonalData {
  margin-top: 30px;
}
.changePersonalData span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  color: #5a6a85;
}
.nameSection {
  display: flex;
  gap: 30px;
  margin-top: 15px;
}
.firstname {
  flex: 1;
}
.secondname {
  flex: 1;
}
.InputText-Setting-Bottom {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #2a3547;
  margin-bottom: 10px;
}
.emailSection {
  display: flex;
  gap: 30px;
  margin-top: 25px;
}
.countrySection {
  margin-top: 25px;
}
.cannotBe {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 12px;
  line-height: 16.8px;
  color: #2a3547;
}
.saveBtn-Bottom {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.saveBtn-Bottom button {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
  height: 38.5px;
  border-radius: 10px;
  border: none;
  background: #bd0c47;
  border-bottom: 3px solid #820d34;
  box-shadow: 0px 3px 1px 0px #00000040;
  width: 14%;
}
.wanttoChangeDiv {
  margin-top: 20px;
}
.wanttoChange {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #2a3547;
}
.clickHere {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  color: #679eff;
}
.settingBottomSection {
  margin-top: 25px;
  border: 1px solid #ececec;
  border-radius: 12px;
  box-shadow: 0px 12px 24px -4px #919eab1f;
  box-shadow: 0px 0px 2px 0px #919eab33;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  height: 430px;
}
.editIcon {
  margin-left: 170px;
  margin-top: -18px;
}
