
.levelImg {
  align-items: center;
  text-align: center;
  justify-content: center;
  
}
.gameLevelFrame {
 
  height: 80vh;
  margin-top: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 3px solid #fff;
  background: #ffffff1a;
  backdrop-filter: blur(25px);
  border-radius: 40px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-left: 20px;
  margin-right: 20px;

}

.level-container {
  position: absolute;
  display: inline-block;
}

.level-number {
  color: #8b002e;
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 24px;
  border: 8px solid #b11d13;
  border-radius: 50%;
  padding-top: 3px;
  padding-left: 10px;
  padding-bottom: 1px;
  padding-right: 10px;
  background: linear-gradient(
    0deg,
    #ffbd00 0%,
    #ffc700 35.4%,
    #ffe300 96.3%,
    #ffe500 100%
  );
}
.lockIcon {
  font-size: 24px;
}

.level {
  position: absolute;
  top: -40%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.level.locked {
  color: gray;
}

.lock-icon {
  font-size: 16px;
}

.level:hover {
  transform: scale(1.1);
}

.level {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

