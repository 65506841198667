.oneVsCreateNewText {
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.oneVsCreateNewText span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #484848;
}
.OneVsModelText {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.OneVsModelText span {
  font-family: "Joti One";
  font-weight: 400;
  font-size: 80px;
  line-height: 45.36px;
  background: linear-gradient(180deg, #ff0303 0%, #ff5e03 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.createGameBtn {
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.createGameBtn button {
  height: 48px;
  width: 60%;
  border-radius: 10px;
  background: #bd0c47;
  border: #820d344d;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #ffffff;
}

.joinGameBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.joinGameBtn button {
  height: 48px;
  width: 60%;
  border-radius: 10px;
  background: #0cbdbd;
  border: #0d58824d;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #ffffff;
}
.oneVsButtonSection {
  margin-top: 15px;
}
.teamVsModal {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.createGameTextOneVs {
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.createGameTextOneVs span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 28px;
  line-height: 35.28px;
  color: #4e4e4e;
}
.selectforStartTextOneVs {
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.selectforStartTextOneVs span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #484848;
}
.game-id-inputs {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.game-id {
  width: 40px;
  height: 40px;
  font-size: 200px;
  text-align: center;
  border: 2px solid #686868;
  border-radius: 5px;
  margin-right: 5px;
}
.game-id:focus {
  outline: none;
  border-color: #ff3377;
  font-size: 20px;
}
.enterGameIdText {
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.enterGameIdText span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #484848;
}
.gameIdText {
  margin-bottom: 5px;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.gameIdText span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 22px;
  line-height: 27.72px;
  color: #4e4e4e;
}
.gameIdShowText {
  margin-bottom: 15px;
  margin-top: 35px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.gameIdShowText span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #484848;
}
.beReadyText {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 25px;
}
.beReadyText span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 22px;
  line-height: 27.72px;
  color: #4e4e4e;
}
.gameWillStartText {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.gameWillStartText span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #4e4e4e;
}
.spinnerAnimation {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 30px;
}

.spinnerAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 3rem;
  height: 3rem;
  border-width: 0.5rem;
}
.spinner-border {
  color: #007bff;
}
.pleseWaitText {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 20px;
}
.pleseWaitText span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #484848;
}

@keyframes loader {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}

.loading-animation {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #007bff;
  animation: loader 0.6s infinite;
  margin: auto;
}
