.welcomeContainer {
  display: flex;
  margin-bottom: 20px;
}
.welcomeName {
  flex: 3;
}
.path {
  flex: 3;
}
.point {
  flex: 1;
  float: inline-end;
}
.welcomeName span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.8px;
  color: #272757;
}
.path span {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 18px;
  line-height: 28.8px;
  color: #272757;
}
.pointLine {
  display: flex;
  margin: auto;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.pointLine span {
  color: #b35b3d;
  font-size: 16px;
  font-weight: 800;
}
.pointsDisplay {
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #ffe871 -18%, #ffd74e 81.9%);
  border-radius: 20px;
  padding: 8px 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #c69c6d;
}

.starIcon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
.plusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
