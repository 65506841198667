.Step4Container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.leftSideCard {
  flex: 1;
  border: 1px solid #fff;
  background: #ffffff1a;
  backdrop-filter: blur(25px);
  border-radius: 12px;
  border: none;
}
.rightSideCard {
  flex: 1;
  border: 1px solid #fff;
  background: #ffffff1a;
  backdrop-filter: blur(25px);
  border-radius: 12px;
  border: none;
}
.topTextSectionStep-4,
.clickStartButtonText {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.readyToGo,
.SelectedSyllabusText {
  margin-top: 30px;
}
.readyToGo span {
  font-family: "PlusJakartaSans";
  font-weight: 800;
  font-size: 26px;
  line-height: 32.76px;
  color: #3a3a3a;
}
.clickStartButtonText {
  margin-top: 10px;
}
.clickStartButtonText span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 16px;
  line-height: 20.16px;
  color: #3a3a3a;
}
.oneVsOnePlayerNameSection {
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
}
.playerOne {
  flex: 1;
}
.vsImage {
  flex: 1;
}
.playerTwo {
  flex: 1;
}
.PlayerNameOneVsOne {
  font-family: "PlusJakartaSans";
  font-weight: 800;
  font-size: 26px;
  line-height: 32.76px;
  color: #3a3a3a;
}
.OneVsOneStartButton {
  margin-top: 60px;
  margin-bottom: 30px;
}
.OneVsOneStartButton button {
  box-shadow: 0px 3px 1px 0px #00000040;
  border-radius: 10px;
  border: 1px solid #820d344d;
  background: #bd0c47;
  height: 48px;
  width: 50%;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #ffffff;
}
.SelectedSyllabusText {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.SelectedSyllabusText span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 22px;
  line-height: 27.72px;
  color: #4e4e4e;
}
.fieldName {
  margin-bottom: 10px;
  margin-top: 10px;
}
.fieldName span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.64px;
  color: #494949;
}
.custom-input {
  border: 1px solid #494949;
  background: transparent;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  color: #494949;
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 14px;
  line-height: 17.64px;
  padding-left: 13px;
}
::placeholder {
  color: #494949;
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 17.64px;
  padding-left: 2%;
}
.OneVsOneForm {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  margin-bottom: 20px;
}
.profileOnevsOne {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

