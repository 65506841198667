.progressBar_correctAnswer {
  margin-left: 30%;
  margin-right: 30%;
}
.noOfQuestion_AP {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.noOfQuestion_AP span {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  line-height: 18.15px;
  color: #3f3f3f;
}
#correctAnswer {
  background: #489f21;
  color: #ffffff;
  display: flex;
}
.correctAnswerIcon {
  padding-left: 65%;
  margin-top: -1%;
}
.buttonSectionAP {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 5px;
}
.PreviousBtnAP button {
  box-shadow: 0px 3px 1px 0px #00000040;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #bd0c47;
  border: 2px solid #bd0c47;
  border-radius: 10px;
  width: 182px;
  height: 42px;
}
.NextBtnAP button {
  box-shadow: 0px 3px 1px 0px #00000040;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #ffffff;
  border: 2px solid #bd0c47;
  background: #bd0c47;
  border-radius: 10px;
  width: 182px;
  height: 42px;
}
.loadingText span{
  font-family: "PlusJakartaSans";
  font-family: 600;
  font-size: 17px;
  line-height: 20px;
  color: #004a8e;
}
