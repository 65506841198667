.questiontypeOneContainer {
  position: absolute;
  z-index: 1;
  margin-top: 25px;
  width: 85%;
}
.answer {
  display: flex;
  flex-direction: column;
}
.QuestionType1Content {
  border: 1px solid #fff;
  background: #11637833;
  border: none;
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.question {
  border: 1px solid #fff;
  background: linear-gradient(180deg, #ffffff -12.5%, #a8d0f9 100%);
  border-radius: 8px;
  height: auto;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  padding-left: 30px;
  padding-top: 23px;
  padding-bottom: 23px;
}
.question span {
  font-family: "PlusJakartaSans";
  font-family: 600;
  font-size: 17px;
  line-height: 20px;
  color: #004a8e;
}
.answer {
  border: 1px solid #fff;
  background: linear-gradient(180deg, #ffffff -12.5%, #a8d0f9 100%);
  border: none;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 8px;
}
.answersText {
  font-family: "PlusJakartaSans";
  font-family: 400;
  font-size: 14px;
  line-height: 17.64px;
  color: #004a8e;
  border: 1px solid #dae4ee;
  background: #ffffff;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 8px;
  height: 50px;
  border-radius: 8px;
  padding-left: 30px;
  padding-top: 13px;
  margin-bottom: 8px;
  display: flex;
}
.custom-radio input[type="radio"] {
  width: 20px;
  height: 20px;
  border-color: #e9e9e9;
  background-color: #e9e9e9;
}
.custom-radio input[type="radio"]:checked {
  background-color: #51b947;
  border-color: #51b947;
}
.clock {
  width: 3%;
  height: 3%;
}
.timeSectionQT {
  display: flex;
}

.timeSectionQT span {
  font-family: "PlusJakartaSans";
  font-family: 600;
  font-size: 20px;
  line-height: 25.2px;
  color: #ffffff;
}
.prograssBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 15px;
}
.prograssBar span {
  font-family: "Inter";
  font-family: 600;
  font-size: 15px;
  line-height: 18.15px;
  color: #3f3f3f;
}
.backgroundTopSectionQT {
  position: relative;
  padding-top: 10px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-between;
}
.BottomImgQT {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}
.ContentQT {
  position: absolute;
  z-index: 10;
  margin-top: auto;
  left: 0;
  width: 100%;
}
.timeAndProgress {
  display: flex;
  gap: 30px;
  padding-left: 100px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.time {
  flex: 1;
  display: flex;
  width: 10%;
  height: 10%;
}
.clock {
  width: 10%;
  height: 10%;
}
.time span {
  margin: auto;
  margin-left: 10px;
  font-family: "PlusJakartaSans";
  font-family: 600;
  font-size: 20px;
  line-height: 25.2px;
  color: #ffffff;
}
.progressBar {
  flex: 2;
  margin: auto;
  padding-right: 200px;
}
.custom-progress-bar .progress-bar {
  background-color: #e39234;
}
.AnswerSection {
  display: flex;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #ffffff -12.5%, #a8d0f9 100%);
  border: none;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 8px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.ImageAnswerSection {
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
}
.TextAnswerSection {
  flex: 2;
}
.AnswerSectionQT3 {
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #ffffff -12.5%, #a8d0f9 100%);
  border: none;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 8px;
  padding-bottom: 50px;
  padding-top: 30px;
  gap: 10px;
  margin-bottom: 10px;
}
.firstRow {
  display: flex;
  gap: 10px;
  padding-left: 100px;
}
.secondRow {
  display: flex;
  gap: 10px;
  padding-left: 100px;
}
.Image1 {
  flex: 1;
}
.Image2 {
  flex: 1;
}
.Image3 {
  flex: 1;
}
.Image4 {
  flex: 1;
}
.Image5 {
  flex: 1;
}
.Image6 {
  flex: 1;
}
.TextAnswerSection.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.image-option {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-option.selected {
  border: 2px solid blue;
  opacity: 0.5;
}
.QuestionPart {
  max-height: 400px;
  overflow-y: auto;
}
