.LoginContainer {
  background: linear-gradient(
    108.13deg,
    #d4deee 2.31%,
    #6b91cc 30.89%,
    #80a9e7 67.08%,
    #d2daeb 97.56%
  );
  padding-top: 50px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.loginForm {
  text-align: center;
  width: 35%;
  border: 3px solid #fff;
  border-radius: 40px;
  backdrop-filter: blur(25px);
  padding-top: 20px;
  padding-left: 80px;
  padding-bottom: 20px;
  padding-right: 80px;
  margin: auto;
  background: transparent;
  position: absolute;
  left: 32%;
  z-index: 1;
  height: auto;
  padding-bottom: 3%;
}
.Welcometext {
  margin-top: 15px;
}
.Welcometext span {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 24px;
  line-height: 30.24px;
  color: #ffffff;
}
.welcomesubtext {
  margin-bottom: 20px;
}
.welcomesubtext span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
}
.InputText {
  float: left;
  margin-bottom: 10px;
}
.InputText span {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
}
.SignInBtn button {
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 20px;
  line-height: 25.2px;
  color: #ffffff;
  background-color: #bd0c47;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 49px;
}
.contunueText {
  margin-top: 10px;
  margin-bottom: 10px;
}
.contunueText span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
}
.GoogleSignInBtn button {
  border: 1px solid #ffffff;
  height: 38.33px;
  border-radius: 34px;
  width: 60%;
  background: transparent;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #ffffff;
}
.SignUpText {
  margin-top: 20px;
}
.SignUpText {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 14px;
  line-height: 17.64px;
  color: #ffffff;
}
.signup {
  font-family: "PlusJakartaSans";
  font-weight: 600;
  font-size: 14px;
  line-height: 17.64px;
  color: #0b3da0;
}
.Sun {
  width: 10%;
  height: 10%;
  float: right;
  margin-top: 30px;
}
.LeftCloud {
  float: left;
  animation: moveLeftRight 4s ease-in-out infinite;
}
.RightCloud {
  float: right;
  margin-top: 100px;
  animation: moveRightLeft 4s ease-in-out infinite;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}

.LeftAnimalImg {
  margin-top: 400px;
}
.RightAnimal {
  float: right;
}
.RightAnimalImg {
  margin-right: 250px;
}
.Bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}
.BottomImg {
  width: 100%;
}
.GrassLeft {
  position: absolute;
  margin-top: -55px;
}
.GrassRight {
  position: absolute;
  left: 82%;
  margin-top: -55px;
}

@media (min-width: 1900px) {
  .LoginContainer {
    padding-top: 100px;
  }
  .Bottom {
    margin-top: 0px;
  }
  .LeftAnimalImg {
    margin-top: 700px;
    margin-left: -90px;
  }
}
