.SignUpContainer {
  background: linear-gradient(
    108.13deg,
    #d4deee 2.31%,
    #6b91cc 30.89%,
    #80a9e7 67.08%,
    #d2daeb 97.56%
  );
  padding-top: 25px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.signUpForm {
  text-align: center;
  width: 35%;
  border: 3px solid #fff;
  border-radius: 40px;
  backdrop-filter: blur(25px);
  padding-top: 20px;
  padding-left: 80px;
  padding-bottom: 20px;
  padding-right: 80px;
  margin: auto;
  background: transparent;
  position: absolute;
  left: 32%;
  height: 90%;
  z-index: 1;
}

.inputfield {
  margin-top: 1px;
  margin-bottom: 3px;
}
label {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 15.12px;
  color: #ffffff;
  margin-left: -130px;
}
.signupBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}
.verficationText {
  float: left;
  margin-bottom: 20px;
}
.verficationText span {
  font-family: "PlusJakartaSans";
  font-weight: 400;
  font-size: 12px;
  line-height: 15.12px;
  color: #ffffff;
}
.numberFeild {
  margin-bottom: 20px;
}
.BackBtn {
  margin-top: 10px;
}
.BackBtn button {
  width: 100%;
  font-family: "PlusJakartaSans";
  font-weight: 700;
  font-size: 18px;
  line-height: 22.68px;
  color: #bd0c47;
  border: none;
  border: 2px solid #bd0c47;
  height: 49px;
  border-radius: 10px;
  background: transparent;
}

.game-id-inputs-signup {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.game-id-signup {
  width: 40px;
  height: 40px;
  font-size: 200px;
  text-align: center;
  border: 2px solid #686868;
  border-radius: 10px;
  margin-right: 5px;
}
.game-id-signup:focus {
  outline: none;
  border-color: #ff3377;
  font-size: 20px;
}
