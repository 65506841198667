.changeSyllabusText{
    text-align: left;
    margin-bottom: 20px;
}
.changeSyllabusText span {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 16px;
  line-height: 20.16px;
  color: #484848;
}

.StartBtn{
    margin-top: 40px;
}
.StartBtn button {
    font-family: "PlusJakartaSans";
    font-weight: 700;
    font-size: 20px;
    line-height: 25.2px;
    color: #ffffff;
    background-color: #bd0c47;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 49px;
  }
