.navNameContainer {
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #fff;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 115px;
  border-radius: 12px;
  background: #11637833;
  border: none;
}
.MenuName {
  flex: 1;
  margin: auto;
}
.navappName {
  flex: 3;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
}
.icon {
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
}
.MenuNameDiv {
  display: flex;
  flex-direction: column;
}
.nameInMenu {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 28px;
  line-height: 36.4px;
  color: #0c4a5b;
}
.nameInpath {
  font-family: "PlusJakartaSans";
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: #0c4a5b;
}
.settingAnimation img {
  width: 33%;
  height: 33%;
}
