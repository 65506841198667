.sideMenu {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.content {
  height: 100vh;
  overflow-y: auto;
}
.logo {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0;
}

.profile-avatar {
  position: absolute;
  bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #4caf50;
}

.ant-menu-item-icon,
.anticon {
  color: #fff !important;
}

.ant-menu-item {
  color: #fff !important;
}

.ant-menu-item:hover,
.ant-menu-item:hover .ant-menu-item-icon,
.ant-menu-item:hover .anticon {
  color: #fff !important;
}

.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-item-selected .anticon,
.ant-menu-item-selected {
  color: #000 !important;
}

.profile-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #4caf50;
}

.content {
  margin: 0px 0px;
  padding: 24;
  background: linear-gradient(
    108.13deg,
    #d4deee 2.31%,
    #6b91cc 30.89%,
    #80a9e7 67.08%,
    #d2daeb 97.56%
  );
}
.hr {
  margin-top: 5px;
  margin-bottom: 25px;
}

.threeDot {
  text-align: center;
  margin-top: 250px;
  margin-top: 10px;
  cursor: pointer;
}

.threeDot span {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
}
.NavLinkName {
  text-decoration: none;
}

.sideMenu {
  position: relative;
}

.scroll-icons {
  position: absolute;
  bottom: 15%;
  left: 37%;
  right: 10px;
  height: 50px;
  width: 50px;
  background-color: #1c3055;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  transition: height 0.5s ease;
}

.scroll-icons:hover {
  height: 200px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.scroll-icon {
  color: white;
  font-size: 24px;
  margin: 10px 0;
  transition: transform 0.3s ease;
}

.scroll-icon:hover {
  transform: scale(1.2);
}

.logout-popup {
  background-color: #2c3e50;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 50px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.scroll-icons:hover .logout-popup,
.logout-popup:hover {
  opacity: 1;
  visibility: visible;
}
.profileSideMenu {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
