.mobile-blocker {
  align-items: center;
  background-color: #ffcc00;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  min-height: 100vh;
  color: #333;
  display: flex;
}

.message-container {
  animation: fadeIn 1.5s ease-in-out, bounceIn 2s ease;
}

.mobile-message {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
  animation: textPulse 2s infinite;
}

.store-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.store-icon {
  width: 160px;
  transition: transform 0.3s ease;
}

.store-icon:hover {
  transform: scale(1.1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes textPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
