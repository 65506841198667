.game-id-inputs-fogot {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 15px;
  margin-top: 100px;
  margin-bottom: 30px;
}
.game-id-fogot {
  width: 40px;
  height: 40px;
  font-size: 200px;
  text-align: center;
  border: 2px solid #686868;
  border-radius: 10px;
  margin-right: 5px;
}
.game-id-fogot:focus {
  outline: none;
  border-color: #ff3377;
  font-size: 20px;
}
